import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { DIRECTUS_PROJECTS_ENDPOINT, DIRECTUS_PERSONAS_ENDPOINT, DIRECTUS_DATASETS_ENDPOINT } from '../../api';
import TopBar from '../TopBar/topBar';
import { format, parseISO } from 'date-fns';
import Snackbar from '../Atoms/snackbar';
import {
  BriefcaseIcon,
  BuildingOfficeIcon,
  LinkIcon,
  PlusIcon,
  ChevronDownIcon,
} from '@heroicons/react/20/solid';
import { Menu, Transition } from '@headlessui/react';
import { UserPlusIcon } from '@heroicons/react/24/outline';
import { CloudArrowUpIcon } from '@heroicons/react/24/outline';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const b2bIndustries = [
  { value: '', label: 'Select an industry' },
  { value: 'Automotive', label: 'Automotive' },
  { value: 'Consumer Electronics', label: 'Consumer Electronics' },
  { value: 'Consumer Goods', label: 'Consumer Goods' },
  { value: 'Education', label: 'Education' },
  { value: 'Energy and Utilities', label: 'Energy and Utilities' },
  { value: 'Financial Services', label: 'Financial Services' },
  { value: 'Government', label: 'Government' },
  { value: 'Healthcare', label: 'Healthcare' },
  { value: 'Hospitality and Tourism', label: 'Hospitality and Tourism' },
  { value: 'Industrial Goods', label: 'Industrial Goods' },
  { value: 'Media and Entertainment', label: 'Media and Entertainment' },
  { value: 'Nonprofit Organizations', label: 'Nonprofit Organizations' },
  { value: 'Professional Services', label: 'Professional Services' },
  { value: 'Real Estate', label: 'Real Estate' },
  { value: 'Retail', label: 'Retail' },
  { value: 'Technology', label: 'Technology' },
  { value: 'Transportation and Logistics', label: 'Transportation and Logistics' },
  { value: 'Telecommunications', label: 'Telecommunications' },
  { value: 'Life Sciences', label: 'Life Sciences' },
  { value: 'Design', label: 'Design' },
  { value: 'Construction', label: 'Construction' },
  { value: 'Marketing and Advertising', label: 'Marketing and Advertising' },
  { value: 'Legal Services', label: 'Legal Services' },
  { value: 'Environmental Services', label: 'Environmental Services' },
  { value: 'Logistics', label: 'Logistics' },
  { value: 'Information Technology', label: 'Information Technology' },
  { value: 'Consulting', label: 'Consulting' },
  { value: 'Healthcare Providers', label: 'Healthcare Providers' },
  { value: 'Biotechnology', label: 'Biotechnology' },
  { value: 'Pharmaceuticals', label: 'Pharmaceuticals' },
  { value: 'Other', label: 'Other' }
];

// List of B2C industries
const b2cIndustries = [
  { value: '', label: 'Select an industry' },
  { value: 'Automotive', label: 'Automotive' },
  { value: 'Consumer Electronics', label: 'Consumer Electronics' },
  { value: 'Consumer Goods', label: 'Consumer Goods' },
  { value: 'Education', label: 'Education' },
  { value: 'Energy and Utilities', label: 'Energy and Utilities' },
  { value: 'Financial Services', label: 'Financial Services' },
  { value: 'Healthcare', label: 'Healthcare' },
  { value: 'Hospitality and Tourism', label: 'Hospitality and Tourism' },
  { value: 'Media and Entertainment', label: 'Media and Entertainment' },
  { value: 'Retail', label: 'Retail' },
  { value: 'Technology', label: 'Technology' },
  { value: 'Telecommunications', label: 'Telecommunications' },
  { value: 'Fashion and Apparel', label: 'Fashion and Apparel' },
  { value: 'Agriculture', label: 'Agriculture' },
  { value: 'Design', label: 'Design' },
  { value: 'Food and Beverage', label: 'Food and Beverage' },
  { value: 'Travel', label: 'Travel' },
  { value: 'Sports', label: 'Sports' },
  { value: 'Publishing', label: 'Publishing' },
  { value: 'Entertainment', label: 'Entertainment' },
  { value: 'Other', label: 'Other' },
];

const ProjectDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [project, setProject] = useState({
    project_name: '',
    short_description: '',
    big_picture: '',
    product_service_details: '',
    identificator: '',
    goals: '',
    industry_type: '',
    industry: '',
    tone_of_voice: '',
  });

  const [personasDetails, setPersonasDetails] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingPersonas, setLoadingPersonas] = useState(true);
  const [error, setError] = useState('');
  const [lastSaved, setLastSaved] = useState('');
  const [saveError, setSaveError] = useState('');
  const autosaveTimeoutRef = useRef(null);
  const token = localStorage.getItem('directus_token');

  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [actionName, setActionName] = useState('');
  const [snackbarType, setSnackbarType] = useState('');

  const [projectDatasets, setProjectDatasets] = useState([]);

  useEffect(() => {
    const fetchProject = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`${DIRECTUS_PROJECTS_ENDPOINT}/${id}`, {
          headers: { Authorization: `Bearer ${token}` },
          params: {
            fields: ['*'],
            deep: {
              data_sets: {
                _limit: -1,
                _fields: ['id', 'name', 'type', 'date_created', 'date_updated']
              }
            }
          }
        });
        setProject(response.data.data);
        setLoading(false);
      } catch (error) {
        setError('Failed to fetch project details.');
        setLoading(false);
      }
    };
    fetchProject();
  }, [id, token]);

  useEffect(() => {
    const fetchProjectDatasets = async () => {
      if (!id) return;
      try {
        const response = await axios.get(DIRECTUS_DATASETS_ENDPOINT, {
          headers: { Authorization: `Bearer ${token}` },
          params: {
            filter: { project: { _eq: id } },
            fields: ['id', 'name', 'type', 'date_created', 'date_updated']
          }
        });
        setProjectDatasets(response.data.data);
      } catch (error) {
      }
    };

    fetchProjectDatasets();
  }, [id, token]);

  const fetchPersonas = async (personaIds) => {
    setLoadingPersonas(true);
    try {
      const responses = await Promise.all(personaIds.map((personaId) =>
        axios.get(`${DIRECTUS_PERSONAS_ENDPOINT}/${personaId}`, {
          headers: { Authorization: `Bearer ${token}` },
        })
      ));
      setPersonasDetails(responses.map((response) => response.data.data));
      setLoadingPersonas(false);
    } catch (error) {
      setError('Failed to fetch personas details.');
      setLoadingPersonas(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProject((prev) => ({ ...prev, [name]: value }));

    if (autosaveTimeoutRef.current) {
      clearTimeout(autosaveTimeoutRef.current);
    }
    autosaveTimeoutRef.current = setTimeout(handleSave, 2000);
  };

  const saveProjectToDirectus = async (projectData) => {
    try {
      const response = await axios.patch(`${DIRECTUS_PROJECTS_ENDPOINT}/${id}`, projectData, {
        headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
      });
      return response.data;
    } catch (error) {
      throw new Error(`Error saving project: ${error.message}`);
    }
  };

  const handleSave = useCallback(async () => {
    try {
      await saveProjectToDirectus(project);
      setLastSaved(new Date().toISOString());
      setSaveError('');
      setSnackbarMessage('Project saved successfully');
      setSnackbarType('success');
      setShowSnackbar(true);
      setTimeout(() => setShowSnackbar(false), 3000);
    } catch (error) {
      setSaveError('Error saving project: ' + error.message);
      setSnackbarMessage('Failed to save project');
      setSnackbarType('error');
      setShowSnackbar(true);
      setTimeout(() => setShowSnackbar(false), 3000);
    }
  }, [project, id, token]);

  function formatDateUpdated(date) {
    if (!date) return 'No update date provided';
    const now = new Date();
    const updatedDate = parseISO(date);
    const diffInSeconds = (now.getTime() - updatedDate.getTime()) / 1000;
    if (diffInSeconds < 60) return `${Math.floor(diffInSeconds)} seconds ago`;
    if (diffInSeconds < 3600) return `${Math.floor(diffInSeconds / 60)} minutes ago`;
    if (diffInSeconds < 86400) return `${Math.floor(diffInSeconds / 3600)} hours ago`;
    if (diffInSeconds < 604800) return `${Math.floor(diffInSeconds / 86400)} days ago`;
    return format(updatedDate, 'dd MMM yyyy');
  }

  const goBack = () => {
    navigate('/projects');
  };

  return (
    <div className="lg:pl-24">
      <TopBar
        onGoBack={goBack}
        loading={loading}
        lastSaved={lastSaved}
        formatDateUpdated={formatDateUpdated}
        showSaveStatus={true}
        customMiddleContent={
          <h1 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
            {project.project_name}
          </h1>
        }
      />

      <div className="lg:ml-24 py-16">
        <div className="max-w-5xl mx-auto bg-white p-10 border border-neutral-200 rounded-lg">
          {saveError && <div className="text-red-500 mb-4">{saveError}</div>}
          {showSnackbar && (
            <Snackbar
              message={snackbarMessage}
              type={snackbarType}
              onClose={() => setShowSnackbar(false)}
            />
          )}

          {/* Basic Project Information */}
          <div className="pb-12">
            <h2 className="text-3xl py-4 leading-7 text-gray-900">Project Details</h2>
            <div className="text-lg leading-6 text-gray-600">
              Manage your project information below.
            </div>

            <div className="mt-10 space-y-8">
              {/* Project Overview Section */}
              <div>
                <h3 className="text-lg font-semibold leading-7 text-gray-900 mb-4">Project Overview</h3>
                <div className="grid grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-6">
                  <div className="sm:col-span-6">
                    <label htmlFor="project-name" className="block text-sm font-medium leading-6 text-gray-900">
                      Project Name
                    </label>
                    <input
                      type="text"
                      name="project_name"
                      value={project.project_name}
                      onChange={handleInputChange}
                      className="mt-2 block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm"
                    />
                  </div>

                  <div className="sm:col-span-3">
                    <label htmlFor="industry-type" className="block text-sm font-medium leading-6 text-gray-900">
                      Industry Type
                    </label>
                    <select
                      name="industry_type"
                      value={project.industry_type}
                      onChange={handleInputChange}
                      className="mt-2 block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm"
                    >
                      <option value="">Select Industry Type</option>
                      <option value="B2B">B2B</option>
                      <option value="B2C">B2C</option>
                    </select>
                  </div>

                  <div className="sm:col-span-3">
                    <label htmlFor="industry" className="block text-sm font-medium leading-6 text-gray-900">
                      Industry
                    </label>
                    <select
                      name="industry"
                      value={project.industry}
                      onChange={handleInputChange}
                      className="mt-2 block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm"
                    >
                      {project.industry_type === 'B2B' 
                        ? b2bIndustries.map(industry => (
                            <option key={industry.value} value={industry.value}>
                              {industry.label}
                            </option>
                          ))
                        : b2cIndustries.map(industry => (
                            <option key={industry.value} value={industry.value}>
                              {industry.label}
                            </option>
                          ))
                      }
                    </select>
                  </div>

                  <div className="col-span-full">
                    <label htmlFor="short-description" className="block text-sm font-medium leading-6 text-gray-900">
                      Short Description
                    </label>
                    <textarea
                      name="short_description"
                      value={project.short_description}
                      onChange={handleInputChange}
                      rows={3}
                      className="mt-2 block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm"
                    />
                  </div>
                </div>
              </div>

              {/* Project Details Section */}
              <div className="border-t border-gray-200 pt-8">
                <h3 className="text-lg font-semibold leading-7 text-gray-900 mb-4">Detailed Information</h3>
                <div className="grid grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-6">
                  <div className="col-span-full">
                    <label htmlFor="big-picture" className="block text-sm font-medium leading-6 text-gray-900">
                      Big Picture
                    </label>
                    <textarea
                      name="big_picture"
                      value={project.big_picture}
                      onChange={handleInputChange}
                      rows={6}
                      className="mt-2 block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm"
                    />
                  </div>

                  <div className="col-span-full">
                    <label htmlFor="product-service-details" className="block text-sm font-medium leading-6 text-gray-900">
                      Product/Service Details
                    </label>
                    <textarea
                      name="product_service_details"
                      value={project.product_service_details}
                      onChange={handleInputChange}
                      rows={6}
                      className="mt-2 block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm"
                    />
                  </div>

                  <div className="col-span-full">
                    <label htmlFor="goals" className="block text-sm font-medium leading-6 text-gray-900">
                      Goals
                    </label>
                    <textarea
                      name="goals"
                      value={project.goals}
                      onChange={handleInputChange}
                      rows={3}
                      className="mt-2 block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm"
                    />
                  </div>

                  <div className="col-span-full">
                    <label htmlFor="tone-of-voice" className="block text-sm font-medium leading-6 text-gray-900">
                      Tone of Voice
                    </label>
                    <textarea
                      name="tone_of_voice"
                      value={project.tone_of_voice}
                      onChange={handleInputChange}
                      rows={3}
                      className="mt-2 block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm"
                      placeholder="Describe the tone of voice for your content (e.g., professional, friendly, casual)"
                    />
                  </div>
                </div>
              </div>

              {/* Competitor Analysis Section */}
              <div className="border-t border-gray-200 pt-8">
                <h3 className="text-lg font-semibold leading-7 text-gray-900 mb-4">Competitor Analysis</h3>
                <div className="grid grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-6">
                  <div className="col-span-full">
                    <label htmlFor="competitors" className="block text-sm font-medium leading-6 text-gray-900">
                      Competitors
                    </label>
                    <textarea
                      name="competitors"
                      value={project.competitors}
                      onChange={handleInputChange}
                      rows={4}
                      className="mt-2 block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm"
                    />
                  </div>

                  <div className="col-span-full">
                    <label htmlFor="market-positioning" className="block text-sm font-medium leading-6 text-gray-900">
                      Market Positioning
                    </label>
                    <textarea
                      name="market_positioning"
                      value={project.market_positioning}
                      onChange={handleInputChange}
                      rows={4}
                      className="mt-2 block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm"
                    />
                  </div>
                </div>
              </div>

              {/* Datasets Section */}
              <div className="border-t border-gray-200 pt-8">
                <div className="flex justify-between items-center mb-4">
                  <h3 className="text-lg font-semibold leading-7 text-gray-900">Project Files & Datasets</h3>
                  <button
                    onClick={() => navigate('/datasets/create-dataset')}
                    className="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-white bg-primary-500 hover:bg-primary-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                  >
                    <PlusIcon className="h-5 w-5 mr-1" />
                    Add Dataset
                  </button>
                </div>
                {projectDatasets.length > 0 ? (
                  projectDatasets.map(dataset => (
                    <div key={dataset.id} className="flex items-center justify-between p-4 bg-white shadow rounded-lg mb-4">
                      <div>
                        <h3 className="text-sm font-medium text-gray-900">{dataset.name}</h3>
                        <p className="text-sm text-gray-500">{dataset.type}</p>
                        <p className="text-xs text-gray-400">
                          Created: {format(new Date(dataset.date_created), 'MMM d, yyyy')}
                        </p>
                      </div>
                      <button
                        onClick={() => navigate(`/datasets/${dataset.id}`)}
                        className="ml-4 inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-primary-500 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      >
                        View Details
                      </button>
                    </div>
                  ))
                ) : (
                  <div className="text-center p-4 bg-gray-50 rounded-lg">
                    <p className="text-sm text-gray-500">No datasets available for this project</p>
                    <button
                      onClick={() => navigate('/datasets/create-dataset')}
                      className="mt-2 inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-indigo-600 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                      Create Dataset
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectDetails;