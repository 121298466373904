import React, { useState, useEffect } from 'react';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid';
import axios from 'axios';
import { DIRECTUS_ONBOARDING_ENDPOINT } from '../../api';
import { useNavigate } from 'react-router-dom';

const directusAPI = axios.create({
  baseURL: `${DIRECTUS_ONBOARDING_ENDPOINT}`,
});

directusAPI.interceptors.request.use(config => {
  const token = localStorage.getItem('directus_token');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
}, error => Promise.reject(error));

const positions = [
  { value: 'marketing_manager', label: 'Marketing Manager' },
  { value: 'content_creator', label: 'Content Creator' },
  { value: 'social_media_manager', label: 'Social Media Manager' },
  { value: 'copywriter', label: 'Copywriter' },
  { value: 'business_owner', label: 'Business Owner' },
  { value: 'product_manager', label: 'Product Manager' },
  { value: 'hr_specialist', label: 'HR Specialist' },
  { value: 'other', label: 'Other' }
];

const teamSizes = [
  { value: '1-10', label: '1-10' },
  { value: '10-25', label: '10-25' },
  { value: '25-50', label: '25-50' },
  { value: '50+', label: '50+' },
];

const goals = [
  { value: 'blog_posts', label: 'Writing Blog Posts' },
  { value: 'ecommerce', label: 'Creating Product Descriptions' },
  { value: 'social_media', label: 'Social Media Content' },
  { value: 'ai_assistant', label: 'AI Writing Assistant' },
  { value: 'custom_content', label: 'Professional Content Creation' },
  { value: 'other', label: 'Other' }
];

const userCounts = [
  { value: '0-100', label: '0-100' },
  { value: '100-1000', label: '100-1,000' },
  { value: '1000-10000', label: '1,000-10,000' },
  { value: '10000+', label: '10,000+' },
];

const Onboarding = () => {
  const navigate = useNavigate();
  const [step, setStep] = useState(0);
  const [name, setName] = useState(() => localStorage.getItem('username') || '');
  const [organizationName, setOrganizationName] = useState('');
  const [position, setPosition] = useState('');
  const [teamSize, setTeamSize] = useState('');
  const [selectedGoals, setSelectedGoals] = useState([]);
  const [userCount, setUserCount] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    const storedName = localStorage.getItem('username');
    if (storedName) {
      setName(storedName);
    }
  }, []);

  const steps = [
    { title: 'Organization Setup', description: 'Let\'s set up your organization' },
    { title: 'Your Position', description: 'What position describes you best?' },
    { title: 'Team Size', description: 'How big is your team?' },
    { title: 'Your Goals', description: 'What do you want to achieve?' },
    { title: 'User Base', description: 'How many monthly users do you have?' },
  ];

  const validateStep = () => {
    switch (step) {
      case 0:
        return organizationName.trim() !== '';
      case 1:
        return position !== '';
      case 2:
        return teamSize !== '';
      case 3:
        return selectedGoals.length > 0;
      case 4:
        return userCount !== '';
      default:
        return true;
    }
  };

  const handleNext = () => {
    if (validateStep()) {
      setError('');
      if (step < steps.length - 1) {
        setStep(step + 1);
      } else {
        handleComplete();
      }
    } else {
      setError('This field is required');
    }
  };

  const handlePrev = () => {
    if (step > 0) {
      setStep(step - 1);
      setError('');
    }
  };

  const handleSkip = () => {
    console.log('Onboarding skipped');
  };

  const handleComplete = async () => {
    try {
      const token = localStorage.getItem('directus_token');
      const userId = localStorage.getItem('user_id');

      if (!token || !userId) {
        throw new Error('User not authenticated');
      }

      console.log('Preparing onboarding data...');
      const onboardingData = {
        first_step: position,
        second_step: teamSize,
        third_step: selectedGoals.join(', '),
        fourth_step: userCount,
        user: userId,
        organisation: {
          name: organizationName,
          team_size: teamSize,
          monthly_users: userCount
        }
      };

      // Create OpenAI Assistant
      const assistantResponse = await axios.post(
        'https://api.openai.com/v1/assistants',
        {
          name: `${organizationName} Assistant`,
          description: `AI Assistant for ${organizationName}`,
          instructions: `You are an AI assistant for ${organizationName}. Your role is to help with content creation and provide assistance based on the available knowledge base.`,
          model: "gpt-4o",
          tools: [
            {
              type: "file_search"
            }
          ]
        },
        {
          headers: {
            'Authorization': `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
            'OpenAI-Beta': 'assistants=v2',
            'Content-Type': 'application/json'
          }
        }
      );

      const assistantId = assistantResponse.data.id;

      // Create Vector Store
      const vectorStoreResponse = await axios.post(
        'https://api.openai.com/v1/vector_stores',
        {
          name: `${organizationName}_ai_writer_knowledge_base`,
          chunking_strategy: {
            type: "static",
            static: {
              max_chunk_size_tokens: 800,
              chunk_overlap_tokens: 400
            }
          }
        },
        {
          headers: {
            'Authorization': `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
            'OpenAI-Beta': 'assistants=v2',
            'Content-Type': 'application/json'
          }
        }
      );

      const vectorStoreId = vectorStoreResponse.data.id;

      // Link Assistant with Vector Store by updating the Assistant
      await axios.post(
        `https://api.openai.com/v1/assistants/${assistantId}`,
        {
          tools: [
            {
              type: "file_search"
            }
          ],
          tool_resources: {
            file_search: {
              vector_store_ids: [vectorStoreId]
            }
          }
        },
        {
          headers: {
            'Authorization': `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
            'OpenAI-Beta': 'assistants=v2',
            'Content-Type': 'application/json'
          }
        }
      );

      // Create workspace with Assistant and Vector Store IDs
      const workspaceData = {
        workspace_name: organizationName,
        team_size: teamSize,
        monthly_users: userCount,
        assistant_id: assistantId,
        vector_store_id: vectorStoreId
      };

      const workspaceResponse = await axios.post(
        'https://panel.reislo.com/items/workspace',
        workspaceData,
        { headers: { Authorization: `Bearer ${token}` } }
      );

      console.log('Workspace created:', workspaceResponse.data);

      // Update user profile
      const userUpdateData = {
        position: position,
        onboarding_completed: true,
        linked_workspace: workspaceResponse.data.data.id,
        trial_ends: new Date(Date.now() + 14 * 24 * 60 * 60 * 1000).toISOString() // 14 days from now
      };

      await axios.patch(
        `https://panel.reislo.com/users/${userId}`,
        userUpdateData,
        { headers: { Authorization: `Bearer ${token}` } }
      );

      navigate('/');
    } catch (error) {
      console.error('Error in onboarding process:', error);
      if (error.response) {
        console.error('Response data:', error.response.data);
        console.error('Response status:', error.response.status);
        console.error('Response headers:', error.response.headers);
      }
      setError(`Failed to complete onboarding: ${error.message}`);
    }
  };

  const renderStep = () => {
    switch (step) {
      case 0:
        return (
          <div className="space-y-4">
            <div className="mt-1 flex rounded-md">
              <input
                type="text"
                name="organizationName"
                id="organizationName"
                className="flex-1 rounded-md border-gray-300 focus:border-primary-500 focus:ring-primary-500 sm:text-sm"
                placeholder="Your Organization Name"
                value={organizationName}
                onChange={(e) => setOrganizationName(e.target.value)}
              />
            </div>
          </div>
        );
      case 1:
        return (
          <div className="space-y-4">
            {positions.map((pos) => (
              <button
                key={pos.value}
                className={`w-full py-4 px-4 rounded-md ${position === pos.value
                  ? 'bg-primary-500 text-white'
                  : 'bg-white border border-gray-300 text-gray-700 hover:bg-gray-50'
                  }`}
                onClick={() => setPosition(pos.value)}
              >
                {pos.label}
              </button>
            ))}
          </div>
        );
      case 2:
        return (
          <div className="space-y-4">
            {teamSizes.map((size) => (
              <button
                key={size.value}
                className={`w-full py-4 px-4 rounded-md ${teamSize === size.value
                  ? 'bg-primary-500 text-white'
                  : 'bg-white border border-gray-300 text-gray-700 hover:bg-gray-50'
                  }`}
                onClick={() => setTeamSize(size.value)}
              >
                {size.label}
              </button>
            ))}
          </div>
        );
      case 3:
        return (
          <div className="grid grid-cols-2 gap-6">
            {goals.map((goal) => (
              <button
                key={goal.value}
                className={` w-full p-4 mx-2 rounded-md ${selectedGoals.includes(goal.value)
                  ? 'bg-primary-500 text-white'
                  : 'bg-white border border-gray-300 text-gray-700 hover:bg-gray-50'
                  }`}
                onClick={() => {
                  setSelectedGoals((prev) =>
                    prev.includes(goal.value)
                      ? prev.filter((g) => g !== goal.value)
                      : [...prev, goal.value]
                  );
                }}
              >
                {goal.label}
              </button>
            ))}
          </div>
        );
      case 4:
        return (
          <div className="space-y-4">
            {userCounts.map((count) => (
              <button
                key={count.value}
                className={`w-full py-4 px-4 rounded-md ${userCount === count.value
                  ? 'bg-primary-500 text-white'
                  : 'bg-white border border-gray-300 text-gray-700 hover:bg-gray-50'
                  }`}
                onClick={() => setUserCount(count.value)}
              >
                {count.label}
              </button>
            ))}
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="fixed z-50 inset-0 bg-gray-100 flex flex-col justify-center items-center p-4 md:p-8">
      <div className="w-full max-w-4xl bg-white py-8 px-6 rounded-lg overflow-hidden mb-16">
        <div className="mb-8 absolute top-0 w-full left-0">
          <div className="overflow-hidden rounded-full bg-gray-200">
            <div
              className="h-2 rounded-full bg-primary-500 transition-all duration-500 ease-in-out"
              style={{ width: `${((step + 1) / steps.length) * 100}%` }}
            />
          </div>
        </div>

        <div className="px-4 py-16 sm:p-6">
          <h2 className="text-center text-3xl font-medium text-primary-900">
            {steps[step].title}
          </h2>
          <p className="text-center text-xl mt-2 text-neutral-800 mb-8">{steps[step].description}</p>
          {renderStep()}
          {error && <p className="mt-2 text-sm text-red-600">{error}</p>}
          <div className="mt-8 flex justify-between">
            <button
              onClick={handlePrev}
              disabled={step === 0}
              className={`inline-flex items-center px-4 py-2 border text-sm font-medium rounded-md ${step === 0
                ? 'bg-gray-300 text-gray-500 cursor-not-allowed'
                : 'text-primary-500 bg-transparent border border-primary-500  focus:ring-primary-500'
                }`}
            >
              <ChevronLeftIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
              Previous
            </button>
            <button
              onClick={handleNext}
              className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-primary-500 hover:bg-primary-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
            >
              {step < steps.length - 1 ? (
                <>
                  Next
                  <ChevronRightIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
                </>
              ) : (
                'Complete'
              )}
            </button>
          </div>
        </div>
      </div>
      <div className="mt-4 text-center hidden">
        <button
          onClick={handleSkip}
          className="text-sm text-gray-600 hover:text-primary-900 mt-6">
          Skip onboarding
        </button>
      </div>
    </div>
  );
};

export default Onboarding;