import React, { useState, useEffect, useRef } from 'react';
import { authService } from '../../services/authService';
import reisloLogo from '../../img/logo-prose-captain.svg';
import backgroundImage from '../../img/bg-login.jpg';
import Snackbar from '../Atoms/snackbar';

const SignIn = ({ onSignIn }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [turnstileToken, setTurnstileToken] = useState(null);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const turnstileContainerRef = useRef(null);

  useEffect(() => {
    // Skip Turnstile on localhost
    if (window.location.hostname === 'localhost') {
      setTurnstileToken('dummy-token-for-localhost');
      return;
    }

    // Clear any existing Turnstile instances
    if (turnstileContainerRef.current) {
      turnstileContainerRef.current.innerHTML = '';
    }

    // Initialize Turnstile
    if (window.turnstile) {
      window.turnstile.render(turnstileContainerRef.current, {
        sitekey: process.env.REACT_APP_TURNSTILE_SITE_KEY,
        callback: function(token) {
          setTurnstileToken(token);
        },
        'theme': 'light',
        'refresh-expired': 'auto'
      });
    }

    return () => {
      if (turnstileContainerRef.current) {
        turnstileContainerRef.current.innerHTML = '';
      }
    };
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setError('');
    setLoading(true);
    // Reset snackbar first
    setShowSnackbar(false);

    try {
      if (!turnstileToken) {
        throw new Error('Please complete the security check');
      }

      const token = await authService.login(email, password);
      onSignIn(token);
    } catch (error) {
      const errorMessage = 'Invalid email or password. Please try again.';
      // Small delay to ensure the snackbar is reset before showing again
      setTimeout(() => {
        setSnackbarMessage(errorMessage);
        setShowSnackbar(true);
      }, 100);
      setLoading(false);
    }
  };

  return (
    <div className="flex min-h-screen h-screen flex-1">
      <div className="flex flex-1 flex-col justify-center px-4 py-12 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
        <div className="mx-auto w-full max-w-sm lg:w-96">
          <div>
            <img
              className="h-10 w-auto hidden"
              src={reisloLogo}
              alt="Reislo logo"
            />
            <h2 className="mt-8 text-4 font-medium text-gray-900">
              Welcome back!
            </h2>
            <p className="mt-2 text-base text-gray-500">
              Not a member?{' '}
              <a href="/signup" className="font-semibold text-primary-600 hover:text-primary-500">
                Register for free
              </a>
            </p>
          </div>

          <div className="mt-10">
            <form onSubmit={handleSubmit} className="space-y-6">
              <div>
                <label htmlFor="email" className="block text-sm/6 font-medium text-gray-900">
                  Email address
                </label>
                <div className="mt-2">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    required
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              <div>
                <label htmlFor="password" className="block text-sm/6 font-medium text-gray-900">
                  Password
                </label>
                <div className="mt-2">
                  <input
                    id="password"
                    name="password"
                    type="password"
                    autoComplete="current-password"
                    required
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              <div className="flex items-center justify-end">
                <div className="text-sm/6">
                  <a href="#" className="font-semibold text-primary-600 hover:text-primary-500">
                    Forgot password?
                  </a>
                </div>
              </div>

              {/* Turnstile container */}
              <div ref={turnstileContainerRef} className="flex justify-center"></div>

              <div>
                <button
                  type="submit"
                  disabled={loading || !turnstileToken}
                  className={`flex w-full justify-center rounded-md px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm ${
                    !turnstileToken 
                    ? 'bg-gray-400 cursor-not-allowed' 
                    : 'bg-primary-600 hover:bg-primary-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600'
                  }`}
                >
                  {loading ? 'Signing in...' : (!turnstileToken ? 'Complete Security Check' : 'Sign in')}
                </button>
              </div>

              {error && (
                <div className="text-center text-red-500 text-sm">
                  {error}
                </div>
              )}
            </form>
          </div>
        </div>
      </div>

      <div className="relative hidden w-0 flex-1 lg:block">
        <img
          alt=""
          src={backgroundImage}
          className="absolute inset-0 h-full w-full object-cover"
        />
      </div>

      {showSnackbar && (
        <Snackbar
          message={snackbarMessage}
          type="error"
          onClose={() => setShowSnackbar(false)}
        />
      )}
    </div>
  );
};

export default SignIn;
