import React, { useEffect, useState } from 'react';
import createProjectIcon from '../../img/create-project.svg';
import provideDataIcon from '../../img/provide-data.svg';
import createContentIcon from '../../img/create-content.svg';

const features = [
  {
    name: 'Create Content',
    description: 'Generate articles, product descriptions, and more using AI trained on your own data and files.',
    icon: createProjectIcon,
    link: '/articles/create-article',
    bgColor: 'bg-white',
    iconColor: 'text-primary-400'
  },
  {
    name: 'Talk with Assistant',
    description: 'Interact with our AI assistant for quick answers and guidance on various topics.',
    icon: provideDataIcon, // Using the same icon as data collection
    link: '/assistants',
    bgColor: 'bg-white',
    iconColor: 'text-orange-400'
  },
  {
    name: 'Create Project',
    description: 'Start a new project to organize your data, and content creation efforts.',
    icon: createContentIcon,
    bgColor: 'bg-white',
    iconColor: 'text-red-400',
    disabled: false,
    label: ''
  },
  // Data Collection block is commented out for now
  
  {
    name: 'Data Collection',
    description: 'Upload and manage your datasets to train the AI and improve content generation accuracy.',
    icon: provideDataIcon,
    bgColor: 'bg-white',
    iconColor: 'text-orange-400',
    disabled: false,
    label: ''
  }
  
];

const posts = [
  {
    id: 1,
    title: 'Boost your conversion rate',
    href: '#',
    description: 'Illo sint voluptas. Error voluptates culpa eligendi. Hic vel totam vitae illo. Non aliquid explicabo necessitatibus unde. Sed exercitationem placeat consectetur nulla deserunt vel. Iusto corrupti dicta.',
    imageUrl: 'https://images.unsplash.com/photo-1496128858413-b36217c2ce36?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=3603&q=80',
    date: 'Mar 16, 2020',
    datetime: '2020-03-16',
    author: {
      name: 'Michael Foster',
      imageUrl: 'https://images.unsplash.com/photo-1519244703995-f4e0f30006d5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    },
  },
  {
    id: 2,
    title: 'Boost your conversion rate',
    href: '#',
    description: 'Illo sint voluptas. Error voluptates culpa eligendi. Hic vel totam vitae illo. Non aliquid explicabo necessitatibus unde. Sed exercitationem placeat consectetur nulla deserunt vel. Iusto corrupti dicta.',
    imageUrl: 'https://images.unsplash.com/photo-1496128858413-b36217c2ce36?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=3603&q=80',
    date: 'Mar 16, 2020',
    datetime: '2020-03-16',
    author: {
      name: 'Michael Foster',
      imageUrl: 'https://images.unsplash.com/photo-1519244703995-f4e0f30006d5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    },
  },
  {
    id: 3,
    title: 'Boost your conversion rate',
    href: '#',
    description: 'Illo sint voluptas. Error voluptates culpa eligendi. Hic vel totam vitae illo. Non aliquid explicabo necessitatibus unde. Sed exercitationem placeat consectetur nulla deserunt vel. Iusto corrupti dicta.',
    imageUrl: 'https://images.unsplash.com/photo-1496128858413-b36217c2ce36?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=3603&q=80',
    date: 'Mar 16, 2020',
    datetime: '2020-03-16',
    author: {
      name: 'Michael Foster',
      imageUrl: 'https://images.unsplash.com/photo-1519244703995-f4e0f30006d5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    },
  },
  // More posts can be added here...
];

const Dashboard = () => {
  const [name, setName] = useState(() => localStorage.getItem('username') || '');

  useEffect(() => {
    const storedName = localStorage.getItem('username');
    if (storedName) {
      setName(storedName);
    }
  }, []);

  return (
    <div className="lg:pl-24 px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto">
        <div className="mb-8 sm:mb-8 mt-4 sm:mt-8">
          <h1 className="text-2xl sm:text-2xl mb-1 font-medium">Hello, {name}</h1>
          <span className="text-lg sm:text-lg text-neutral-800">What would you like to do today?</span>
        </div>
        <div className="grid gap-4 sm:gap-6 grid-cols-1 sm:grid-cols-3 lg:grid-cols-12 mt-2 sm:mt-4 pb-12 sm:pb-16">
          {features.map((feature, index) => (
            <div 
              key={index} 
              className={`relative sm:col-span-1 lg:col-span-6 ${feature.bgColor} border border-neutral-200 
                ${feature.disabled ? 'opacity-60 cursor-not-allowed' : 'hover:shadow hover:cursor-pointer'} 
                text-center p-4 sm:p-6 rounded-lg`}
            >
              <div className="flex flex-col gap-x-6 items-center px-4 sm:px-8 py-4 sm:py-5">
                <div className="mb-3 sm:mb-4">
                  <img src={feature.icon} alt={feature.name} className={`h-32 w-32 sm:h-22 sm:w-22 ${feature.iconColor}`} />
                </div>
                <div>
                  <h2 className="text-lg sm:text-2xl font-medium mt-2 sm:mt-3">{feature.name}</h2>
                  <dd className="mt-2 text-sm sm:text-base leading-6 sm:leading-7 text-gray-800 opacity-80">{feature.description}</dd>
                  {feature.label && (
                    <span className="mt-2 inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700">
                      {feature.label}
                    </span>
                  )}
                </div>
              </div>
              {feature.link && !feature.disabled && <a href={feature.link} className="absolute inset-0"></a>}
            </div>
          ))}
        </div>

        <div className="mb-8 mt-24 hidden">
          <div className="">
            <h1 className="text-2xl mb-0 font-medium">Knowledge & Insights</h1>
            <h2 className="text-base mt-0 text-neutral-600">Power up your User Experience Management with Insights</h2>
          </div>
          <div className="mx-auto grid max-w-2xl auto-rows-fr grid-cols-1 gap-8 mt-6 mb-32 lg:mx-0 lg:max-w-none lg:grid-cols-3">
            {posts.map((post) => (
              <article
                key={post.id}
                className="relative isolate flex flex-col justify-end overflow-hidden rounded-2xl bg-gray-900 px-8 pb-8 pt-80 sm:pt-48 lg:pt-80"
              >
                <img src={post.imageUrl} alt="" className="absolute inset-0 -z-10 h-full w-full object-cover" />
                <div className="absolute inset-0 -z-10 bg-gradient-to-t from-gray-900 via-gray-900/40" />
                <div className="absolute inset-0 -z-10 rounded-2xl ring-1 ring-inset ring-gray-900/10" />
                <div className="flex flex-wrap items-center gap-y-1 overflow-hidden text-sm leading-6 text-gray-300">
                  <time dateTime={post.datetime} className="mr-8">
                    {post.date}
                  </time>
                  <div className="-ml-4 flex items-center gap-x-4">
                    <svg viewBox="0 0 2 2" className="-ml-0.5 h-0.5 w-0.5 flex-none fill-white/50">
                      <circle cx={1} cy={1} r={1} />
                    </svg>
                    <div className="flex gap-x-2.5">
                      <img src={post.author.imageUrl} alt="" className="h-6 w-6 flex-none rounded-full bg-white/10" />
                      {post.author.name}
                    </div>
                  </div>
                </div>
                <h3 className="mt-3 text-lg font-semibold leading-6 text-white">
                  <a href={post.href}>
                    <span className="absolute inset-0" />
                    {post.title}
                  </a>
                </h3>
              </article>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;