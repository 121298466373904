import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { DIRECTUS_PROJECTS_ENDPOINT } from '../../api'; // Adjust the import path as necessary

import {
  CheckCircleIcon,
  InformationCircleIcon,
} from '@heroicons/react/24/outline';

const CreateProject = () => {
  const [projectName, setProjectName] = useState('');
  const [shortDescription, setShortDescription] = useState('');
  const [goals, setGoals] = useState('');
  const [bigPicture, setBigPicture] = useState('');
  const [productServiceDetails, setProductServiceDetails] = useState('');
  const [industryType, setIndustryType] = useState(''); // New state for B2B/B2C
  const [industry, setIndustry] = useState(''); // New state for industry
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const token = localStorage.getItem('directus_token'); // Retrieve the stored token
  const [toneOfVoice, setToneOfVoice] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    setError('');

    try {
      const response = await axios.post(
        DIRECTUS_PROJECTS_ENDPOINT,
        {
          project_name: projectName,
          status: 'published',
          industry_type: industryType,
          industry: industry,
          short_description: shortDescription,
          product_service_details: productServiceDetails,
          tone_of_voice: toneOfVoice,
          owner: localStorage.getItem('user_id'),
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );

      if (response.status === 200) {
        navigate('/projects');
      }
    } catch (error) {
      setError('Failed to create project. ' + (error.response?.data?.message || error.message));
    } finally {
      setLoading(false);
    }
  };

  // List of B2B industries
  const b2bIndustries = [
    { value: '', label: 'Select an industry' },
    { value: 'Automotive', label: 'Automotive' },
    { value: 'Consumer Electronics', label: 'Consumer Electronics' },
    { value: 'Consumer Goods', label: 'Consumer Goods' },
    { value: 'Education', label: 'Education' },
    { value: 'Energy and Utilities', label: 'Energy and Utilities' },
    { value: 'Financial Services', label: 'Financial Services' },
    { value: 'Government', label: 'Government' },
    { value: 'Healthcare', label: 'Healthcare' },
    { value: 'Hospitality and Tourism', label: 'Hospitality and Tourism' },
    { value: 'Industrial Goods', label: 'Industrial Goods' },
    { value: 'Media and Entertainment', label: 'Media and Entertainment' },
    { value: 'Nonprofit Organizations', label: 'Nonprofit Organizations' },
    { value: 'Professional Services', label: 'Professional Services' },
    { value: 'Real Estate', label: 'Real Estate' },
    { value: 'Retail', label: 'Retail' },
    { value: 'Technology', label: 'Technology' },
    { value: 'Transportation and Logistics', label: 'Transportation and Logistics' },
    { value: 'Telecommunications', label: 'Telecommunications' },
    { value: 'Life Sciences', label: 'Life Sciences' },
    { value: 'Design', label: 'Design' },
    { value: 'Construction', label: 'Construction' },
    { value: 'Marketing and Advertising', label: 'Marketing and Advertising' },
    { value: 'Legal Services', label: 'Legal Services' },
    { value: 'Environmental Services', label: 'Environmental Services' },
    { value: 'Logistics', label: 'Logistics' },
    { value: 'Information Technology', label: 'Information Technology' },
    { value: 'Consulting', label: 'Consulting' },
    { value: 'Healthcare Providers', label: 'Healthcare Providers' },
    { value: 'Biotechnology', label: 'Biotechnology' },
    { value: 'Pharmaceuticals', label: 'Pharmaceuticals' },
    { value: 'Other', label: 'Other' },
  ];

  // List of B2C industries
  const b2cIndustries = [
    { value: '', label: 'Select an industry' },
    { value: 'Automotive', label: 'Automotive' },
    { value: 'Consumer Electronics', label: 'Consumer Electronics' },
    { value: 'Consumer Goods', label: 'Consumer Goods' },
    { value: 'Education', label: 'Education' },
    { value: 'Energy and Utilities', label: 'Energy and Utilities' },
    { value: 'Financial Services', label: 'Financial Services' },
    { value: 'Healthcare', label: 'Healthcare' },
    { value: 'Hospitality and Tourism', label: 'Hospitality and Tourism' },
    { value: 'Media and Entertainment', label: 'Media and Entertainment' },
    { value: 'Retail', label: 'Retail' },
    { value: 'Technology', label: 'Technology' },
    { value: 'Telecommunications', label: 'Telecommunications' },
    { value: 'Fashion and Apparel', label: 'Fashion and Apparel' },
    { value: 'Agriculture', label: 'Agriculture' },
    { value: 'Design', label: 'Design' },
    { value: 'Food and Beverage', label: 'Food and Beverage' },
    { value: 'Travel', label: 'Travel' },
    { value: 'Sports', label: 'Sports' },
    { value: 'Publishing', label: 'Publishing' },
    { value: 'Entertainment', label: 'Entertainment' },
    { value: 'Other', label: 'Other' },
  ];

  const getIndustryOptions = () => {
    return industryType === 'B2B' ? b2bIndustries : b2cIndustries;
  };

  return (
    <div className="lg:ml-48 py-16">
      {isLoading}
      <div className="max-w-5xl mx-auto bg-white p-10 border border-neutral-200 rounded-lg">
        <div className="pb-12">
          <h2 className="text-3xl py-4 leading-7 text-gray-900">Create New Project</h2>
          <div className="text-lg leading-6 text-gray-600">
            Fill out the form below to create a new project.
          </div>

          <form onSubmit={handleSubmit} className="mt-10">
            <div className="grid grid-cols-1 gap-x-6 gap-y-6 sm:grid-cols-6">
              <div className="sm:col-span-6">
                <label htmlFor="project-name" className="block text-sm font-medium leading-6 text-gray-900">
                  Project Name
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    placeholder="i.e. My Amazing Project"
                    id="project-name"
                    value={projectName}
                    onChange={(e) => setProjectName(e.target.value)}
                    required />
                </div>
              </div>

              <div className="sm:col-span-6">
                <label htmlFor="industry-type" className="block text-sm font-medium leading-6 text-gray-900">
                  Industry Type
                </label>
                <div className="mt-2">
                  <select
                    id="industry-type"
                    value={industryType}
                    onChange={(e) => setIndustryType(e.target.value)}
                    required
                  >
                    <option value="">Select Industry Type</option>
                    <option value="B2B">B2B</option>
                    <option value="B2C">B2C</option>
                  </select>
                </div>
              </div>

              <div className="sm:col-span-6">
                <label htmlFor="industry" className="block text-sm font-medium leading-6 text-gray-900">
                  Industry
                </label>
                <div className="mt-2">
                  <select
                    id="industry"
                    value={industry}
                    onChange={(e) => setIndustry(e.target.value)}
                    required
                  >
                    {getIndustryOptions().map(industry => (
                      <option key={industry.value} value={industry.value}>
                        {industry.label}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="col-span-full">
                <label htmlFor="short-description" className="block text-sm font-medium leading-6 text-gray-900">
                  Short Description
                </label>
                <div className="mt-2">
                  <textarea
                    id="short-description"
                    placeholder="Explain what you do in few sentences"
                    value={shortDescription}
                    onChange={(e) => setShortDescription(e.target.value)}
                    required
                    rows={3}
                  />
                </div>

                <div className="rounded-md bg-neutral-50 mb-8 p-4 mt-4 ">
                  <div className="flex">
                    <div className="flex-shrink-0">
                      <InformationCircleIcon className="h-6 w-6 text-neutral-800" aria-hidden="true" />
                    </div>
                    <div className="ml-3">
                      <h3 className="text-sm font-medium text-neutral-800">Get to Know Yourself Better</h3>
                      <div className="mt-2 text-sm text-neutral-800">
                        <p>Knowing what you do, we will be able to better adjust the operation of individual system functions</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-span-full hidden">
                <label htmlFor="big-picture" className="block text-sm font-medium leading-6 text-gray-900">
                  Big Picture
                </label>
                <div className="mt-2">
                  <textarea
                    id="big-picture"
                    value={bigPicture}
                    onChange={(e) => setBigPicture(e.target.value)}
                    rows={3}
                  />
                </div>
              </div>
              <div className="col-span-full">
                <label htmlFor="product-service-details" className="block text-sm font-medium leading-6 text-gray-900">
                  Main Product/Service Details
                </label>
                <div className="mt-2">
                  <textarea
                    placeholder="What are your main Services or selling products?"
                    id="product-service-details"
                    value={productServiceDetails}
                    onChange={(e) => setProductServiceDetails(e.target.value)}
                    rows={3}
                    required
                  />
                </div>
              </div>
              <div className="col-span-full">
                <label htmlFor="tone-of-voice" className="block text-sm font-medium leading-6 text-gray-900">
                  Tone of Voice
                </label>
                <div className="mt-2">
                  <textarea
                    placeholder="Describe the tone of voice for your content (e.g., professional, friendly, casual)"
                    id="tone-of-voice"
                    value={toneOfVoice}
                    onChange={(e) => setToneOfVoice(e.target.value)}
                    rows={3}
                    required
                    className="mt-2 block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm"
                  />
                </div>
              </div>
            </div>

            {error && <div className="text-red-500 text-sm mt-2">{error}</div>}
            <div className="mt-6 flex items-center justify-end gap-x-6">
              <button type="button" className="px-6 py-3 text-base leading-6 text-gray-900" onClick={() => navigate('/projects')}>
                Cancel
              </button>
              <button
                type="submit"
                className="rounded-md bg-primary-500 px-6 py-3 text-base text-white shadow-sm hover:bg-primary-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600"
              >
                {isLoading ? 'Creating...' : 'Create Project'}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CreateProject;
