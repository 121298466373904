import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getOpenAIFile, deleteVectorStoreFile } from '../../api';
import { format } from 'date-fns';
import TopBar from '../TopBar/topBar';
import { Menu, Transition } from '@headlessui/react';
import { Fragment } from 'react';

function DatasetSingle() {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { id } = useParams();
  const navigate = useNavigate();
  const [openAIFile, setOpenAIFile] = useState(null);

  useEffect(() => {
    const fetchOpenAIFile = async () => {
      try {
        setLoading(true);
        const response = await getOpenAIFile(id);
        setOpenAIFile(response);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching OpenAI file:', error);
        setError('Failed to fetch OpenAI file details');
        setLoading(false);
      }
    };

    fetchOpenAIFile();
  }, [id]);

  const formatFileSize = (bytes) => {
    const sizes = ['Bytes', 'KB', 'MB', 'GB'];
    if (bytes === 0) return '0 Byte';
    const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
  };

  const formatDateUpdated = (timestamp) => {
    return format(new Date(timestamp * 1000), 'PPP');
  };

  const goBack = () => {
    navigate('/datasets');
  };

  const handleDelete = async () => {
    if (window.confirm('Are you sure you want to delete this file? This action cannot be undone.')) {
      try {
        setLoading(true);
        await deleteVectorStoreFile(id);
        navigate('/datasets');
      } catch (error) {
        console.error('Error deleting file:', error);
        setError('Failed to delete file');
      } finally {
        setLoading(false);
      }
    }
  };

  const renderRemoveButton = () => (
    <button
      onClick={handleDelete}
      className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
    >
      <svg 
        xmlns="http://www.w3.org/2000/svg" 
        className="mr-2 h-5 w-5" 
        viewBox="0 0 20 20" 
        fill="currentColor"
      >
        <path fillRule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clipRule="evenodd" />
      </svg>
      Remove file
    </button>
  );

  const renderOpenAIFileDetails = () => {
    if (!openAIFile) return null;

    return (
      <div className="mt-8 bg-white shadow sm:rounded-lg">
        <div className="px-4 py-5 sm:p-6">
          <h3 className="text-lg font-medium leading-6 text-gray-900">
            OpenAI File Details
          </h3>
          <div className="mt-5 border-t border-gray-200">
            <dl className="divide-y divide-gray-200">
              <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4">
                <dt className="text-sm font-medium text-gray-500">File ID</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2">{openAIFile.id}</dd>
              </div>
              <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4">
                <dt className="text-sm font-medium text-gray-500">Filename</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2">{openAIFile.filename}</dd>
              </div>
              <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4">
                <dt className="text-sm font-medium text-gray-500">Purpose</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2">{openAIFile.purpose}</dd>
              </div>
              <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4">
                <dt className="text-sm font-medium text-gray-500">Size</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2">{formatFileSize(openAIFile.bytes)}</dd>
              </div>

              <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4">
                <dt className="text-sm font-medium text-gray-500">Status</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2">
                  <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                    openAIFile.status === 'processed' 
                      ? 'bg-green-100 text-green-800' 
                      : 'bg-yellow-100 text-yellow-800'
                  }`}>
                    {openAIFile.status}
                  </span>
                </dd>
              </div>
              <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4">
                <dt className="text-sm font-medium text-gray-500">Created At</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2">
                  {formatDateUpdated(openAIFile.created_at)}
                </dd>
              </div>
              <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4">
                <dt className="text-sm font-medium text-gray-500">Actions</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2">
                  <button
                    onClick={handleDelete}
                    className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700"
                  >
                    Delete File
                  </button>
                </dd>
              </div>
            </dl>
          </div>
        </div>
      </div>
    );
  };

  if (loading) return (
    <div className="flex justify-center items-center h-64">
      <div className="text-gray-600">Loading file details...</div>
    </div>
  );
  
  if (error) return (
    <div className="flex justify-center items-center h-64">
      <div className="text-red-600">{error}</div>
    </div>
  );

  return (
    <div className="lg:pl-24">
      <TopBar
        onGoBack={goBack}
        formatDateUpdated={() => formatDateUpdated(openAIFile?.created_at)}
        customMiddleContent={
          <span>{openAIFile?.filename || 'OpenAI File Details'}</span>
        }
        customRightContent={renderRemoveButton()}
      />
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        {renderOpenAIFileDetails()}
      </div>
    </div>
  );
}

export default DatasetSingle;