import React, { useState, useEffect } from 'react';
import { PhotoIcon, UserCircleIcon } from '@heroicons/react/24/solid';
import axios from 'axios';
import { DIRECTUS_INSTANCE } from '../../api';
import Snackbar from '../Atoms/snackbar';
import { uploadImageToDirectus } from '../ArticlesGenerator/fileUpload';

const INPUT_CLASSES = "block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6";

const FORM_FIELDS = [
  { name: 'firstName', label: 'First name', type: 'text', span: 'sm:col-span-3', autoComplete: 'given-name' },
  { name: 'lastName', label: 'Last name', type: 'text', span: 'sm:col-span-3', autoComplete: 'family-name' },
  { name: 'email', label: 'Email address', type: 'email', span: 'sm:col-span-3', autoComplete: 'email' },
  { name: 'title', label: 'Title', type: 'text', span: 'sm:col-span-3' },
  { name: 'position', label: 'Position', type: 'select', span: 'sm:col-span-3', options: [
    { value: 'marketing_manager', label: 'Marketing Manager' },
    { value: 'content_creator', label: 'Content Creator' },
    { value: 'social_media_manager', label: 'Social Media Manager' },
    { value: 'copywriter', label: 'Copywriter' },
    { value: 'business_owner', label: 'Business Owner' },
    { value: 'product_manager', label: 'Product Manager' },
    { value: 'hr_specialist', label: 'HR Specialist' },
    { value: 'other', label: 'Other' }
  ]},
  { name: 'description', label: 'Description', type: 'textarea', span: 'col-span-full' },
  { name: 'goals', label: 'Goals', type: 'multiselect', span: 'col-span-full', options: [
    { value: 'blog_posts', label: 'Writing Blog Posts' },
    { value: 'ecommerce', label: 'Creating Product Descriptions' },
    { value: 'social_media', label: 'Social Media Content' },
    { value: 'ai_assistant', label: 'AI Writing Assistant' },
    { value: 'custom_content', label: 'Professional Content Creation' },
    { value: 'other', label: 'Other' }
  ]},

];

const AI_FIELDS = [
  { name: 'assistant_prompt', label: 'AI Assistant Instructions', type: 'textarea', span: 'col-span-full' },
];

export default function ProfileForm() {
  const [formData, setFormData] = useState(Object.fromEntries(FORM_FIELDS.map(field => [field.name, ''])));
  const [workspace, setWorkspace] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarType, setSnackbarType] = useState('');

  useEffect(() => {
    const fetchUserData = async () => {
      const token = localStorage.getItem('directus_token');
      if (!token) return;

      try {
        const response = await axios.get('https://panel.reislo.com/users/me', {
          headers: { Authorization: `Bearer ${token}` },
          params: {
            fields: ['*', 'linked_workspace.*', 'avatar.*']
          }
        });

        const userData = response.data.data;
        console.log('Fetched user data:', userData);
        const avatarUrl = userData.avatar ? `${DIRECTUS_INSTANCE}/assets/${userData.avatar.id}` : null;

        // Get workspace data including assistant_prompt and widget_token
        if (userData.linked_workspace) {
          const workspaceResponse = await axios.get(`${DIRECTUS_INSTANCE}/items/workspace/${userData.linked_workspace.id}`, {
            headers: { Authorization: `Bearer ${token}` },
            params: {
              fields: ['*', 'assistant_prompt', 'widget_token']
            }
          });
          
          console.log('Workspace data:', workspaceResponse.data.data);
          
          setFormData(prevData => ({
            ...prevData,
            firstName: userData.first_name || '',
            lastName: userData.last_name || '',
            email: userData.email || '',
            description: userData.description || '',
            location: userData.location || '',
            title: userData.title || '',
            position: userData.position || '',
            goals: userData.goals || '',
            avatar: avatarUrl,
            assistant_prompt: workspaceResponse.data.data.assistant_prompt || '',
            widget_token: workspaceResponse.data.data.widget_token || ''
          }));

          setWorkspace(userData.linked_workspace.workspace_name || 'No workspace name available');
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
        setError('Failed to load user data');
        setWorkspace('Error loading workspace information');
      }
    };

    fetchUserData();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevData => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');

    const token = localStorage.getItem('directus_token');
    if (!token) {
      setError('No authentication token found');
      setLoading(false);
      return;
    }

    try {
      await axios.patch(`${DIRECTUS_INSTANCE}/users/me`, formData, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setSnackbarMessage('Profile updated successfully');
      setSnackbarType('success');
      setShowSnackbar(true);
      setTimeout(() => setShowSnackbar(false), 3000);
    } catch (error) {
      console.error('Error updating profile:', error);
      setSnackbarMessage('Failed to update profile');
      setSnackbarType('error');
      setShowSnackbar(true);
      setTimeout(() => setShowSnackbar(false), 3000);
    } finally {
      setLoading(false);
    }
  };

  const handleImageUpload = async (file) => {
    try {
      const uploadResponse = await uploadImageToDirectus(file);
      if (uploadResponse.success) {
        const token = localStorage.getItem('directus_token');
        // Update user's avatar in Directus
        await axios.patch(`${DIRECTUS_INSTANCE}/users/me`, {
          avatar: uploadResponse.file.id
        }, {
          headers: { Authorization: `Bearer ${token}` }
        });

        // Set the avatar URL in both formData and localStorage
        const avatarUrl = uploadResponse.file.url;
        setFormData(prev => ({
          ...prev,
          avatar: avatarUrl
        }));
        localStorage.setItem('user_avatar', avatarUrl);

        return uploadResponse.file;
      } else {
        throw new Error(uploadResponse.error);
      }
    } catch (error) {
      console.error('Error uploading image:', error);
      throw error;
    }
  };

  const renderField = ({ name, label, type, span, autoComplete, options }) => (
    <div key={name} className={span}>
      <label htmlFor={name} className="block text-sm font-medium leading-6 text-gray-900">
        {label}
      </label>
      <div className="mt-2">
        {type === 'textarea' ? (
          <textarea
            name={name}
            id={name}
            value={formData[name]}
            onChange={handleChange}
            rows={3}
            className={INPUT_CLASSES}
          />
        ) : type === 'select' ? (
          <select
            name={name}
            id={name}
            value={formData[name]}
            onChange={handleChange}
            className={INPUT_CLASSES}
          >
            <option value="">Select {label}</option>
            {options.map(option => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        ) : type === 'multiselect' ? (
          <div className="grid grid-cols-2 gap-4">
            {options.map(option => (
              <label key={option.value} className="flex items-center space-x-2">
                <input
                  type="checkbox"
                  name={name}
                  value={option.value}
                  checked={(formData[name] || '').includes(option.value)}
                  onChange={(e) => {
                    const currentGoals = formData[name] ? formData[name].split(',').map(g => g.trim()) : [];
                    const newGoals = e.target.checked
                      ? [...currentGoals, option.value]
                      : currentGoals.filter(g => g !== option.value);
                    handleChange({
                      target: {
                        name,
                        value: newGoals.join(', ')
                      }
                    });
                  }}
                  className="h-4 w-4 rounded border-gray-300 text-primary-600 focus:ring-primary-600"
                />
                <span className="text-sm text-gray-700">{option.label}</span>
              </label>
            ))}
          </div>
        ) : (
          <input
            type={type}
            name={name}
            id={name}
            value={formData[name]}
            onChange={handleChange}
            autoComplete={autoComplete}
            className={INPUT_CLASSES}
          />
        )}
      </div>
    </div>
  );

  return (
    <div className="lg:pl-72 pt-16 mx-auto max-w-7xl pb-40">
      <form onSubmit={handleSubmit}>
        <div className="space-y-12">
          <div className="border-b border-gray-900/10 pb-12">
            <h2 className="text-3xl font-medium leading-7 text-gray-900">Profile</h2>
            <p className="mt-4 text-sm leading-6 text-gray-600">
              This information will be displayed publicly so be careful what you share.
            </p>
  
            <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              <div className="col-span-full">
                <label htmlFor="photo" className="block text-sm font-medium leading-6 text-gray-900">
                  Photo
                </label>
                <div className="mt-2 flex items-center gap-x-3">
                  {formData.avatar ? (
                    <img 
                      src={formData.avatar}
                      alt="Profile" 
                      className="h-12 w-12 rounded-full object-cover"
                    />
                  ) : (
                    <div className="h-12 w-12 rounded-full bg-primary-100 flex items-center justify-center text-base font-regular text-primary-500">
                      {(formData.firstName?.substring(0, 1) + formData.lastName?.substring(0, 1)).toUpperCase()}
                    </div>
                  )}
                  <input
                    type="file"
                    id="photo"
                    accept="image/*"
                    className="hidden"
                    onChange={(e) => {
                      const file = e.target.files?.[0];
                      if (file) {
                        setLoading(true);
                        handleImageUpload(file)
                          .then(() => {
                            setSnackbarMessage('Profile photo updated successfully');
                            setSnackbarType('success');
                            setShowSnackbar(true);
                          })
                          .catch((error) => {
                            setSnackbarMessage('Failed to upload image: ' + error.message);
                            setSnackbarType('error');
                            setShowSnackbar(true);
                          })
                          .finally(() => {
                            setLoading(false);
                          });
                      }
                    }}
                  />
                  <button
                    type="button"
                    onClick={() => document.getElementById('photo').click()}
                    className="rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                  >
                    Change
                  </button>
                </div>
              </div>
  
              {/* Workspace field (non-editable) */}
              <div className="sm:col-span-3">
                <label className="block text-sm font-medium leading-6 text-gray-900">
                  Workspace
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    value={workspace}
                    readOnly
                    className={`${INPUT_CLASSES} bg-gray-100`}
                  />
                </div>
              </div>
  
              {FORM_FIELDS.map(renderField)}
            </div>
          </div>

          <div className="border-b border-gray-900/10 pb-12">
            <h2 className="text-3xl font-medium leading-7 text-gray-900">Artificial Intelligence Settings</h2>
            <p className="mt-4 text-sm leading-6 text-gray-600">
              This section contains your AI Assistant's instructions. These instructions help guide how the AI interacts with users
              and handles requests within your workspace.
            </p>

            <div className="mt-10">
              <div className="flex items-center gap-x-3">
                <textarea
                  value={formData.assistant_prompt || ''}
                  readOnly
                  rows={4}
                  className={`${INPUT_CLASSES} bg-gray-100 w-full`}
                />
              </div>
              <div className="mt-2 text-sm text-gray-500">
                {formData.assistant_prompt ? 'Assistant instructions are set' : 'No assistant instructions available'}
              </div>
            </div>
          </div>

          <div className="border-b border-gray-900/10 pb-12">
            <h2 className="text-3xl font-medium leading-7 text-gray-900">Security Credentials</h2>
            <p className="mt-4 text-sm leading-6 text-gray-600">
              This section contains sensitive information. Please keep your widget token secure and never share it with anyone. 
              This token provides access to your account's features and should be treated like a password.
            </p>

            <div className="mt-10">
              <div className="flex items-center gap-x-3">
                <input
                  type="password"
                  value={formData.widget_token || ''}
                  readOnly
                  className={`${INPUT_CLASSES} bg-gray-100`}
                  id="widget-token"
                />
                <button
                  type="button"
                  onClick={() => {
                    const input = document.getElementById('widget-token');
                    input.type = input.type === 'password' ? 'text' : 'password';
                  }}
                  className="rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                >
                  Show/Hide
                </button>
              </div>
              <div className="mt-2 text-sm text-gray-500">
                {formData.widget_token ? 'Token is present' : 'No token available'}
              </div>
            </div>
          </div>
        </div>
  
        {error && <p className="text-red-500 mt-4">{error}</p>}
  
        <div className="mt-6 flex items-center justify-end gap-x-6">
          <button type="button" className="text-sm font-semibold leading-6 text-gray-900">
            Cancel
          </button>
          <button
            type="submit"
            disabled={loading}
            className="rounded-md bg-primary-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-primary-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600 disabled:opacity-50"
          >
            {loading ? 'Saving...' : 'Save'}
          </button>
        </div>
      </form>
      {showSnackbar && (
        <Snackbar
          message={snackbarMessage}
          type={snackbarType}
          onClose={() => setShowSnackbar(false)}
        />
      )}
    </div>
  );
}