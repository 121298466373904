import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { DIRECTUS_INSTANCE } from '../../api';
import reisloLogo from '../../img/logo-prose-captain-white.svg';
import { RocketLaunchIcon } from '@heroicons/react/24/solid';
import PasswordStrengthBar from 'react-password-strength-bar';

const Register = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [firstName, setFirstName] = useState(''); // State for first name
  const [lastName, setLastName] = useState(''); // State for last name
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [loading, setLoading] = useState(false); // NEW: Loading state
  const [isRegistered, setIsRegistered] = useState(false); // New state for success screen
  const [turnstileToken, setTurnstileToken] = useState(null);
  const turnstileContainerRef = useRef(null);

  useEffect(() => {
    // Skip Turnstile on localhost
    if (window.location.hostname === 'localhost') {
      setTurnstileToken('dummy-token-for-localhost');
      return;
    }

    // Clear any existing Turnstile instances
    if (turnstileContainerRef.current) {
      turnstileContainerRef.current.innerHTML = '';
    }

    // Initialize Turnstile
    if (window.turnstile) {
      window.turnstile.render(turnstileContainerRef.current, {
        sitekey: process.env.REACT_APP_TURNSTILE_SITE_KEY,
        callback: function(token) {
          setTurnstileToken(token);
        },
        'theme': 'light',
        'refresh-expired': 'auto'
      });
    }

    return () => {
      if (turnstileContainerRef.current) {
        turnstileContainerRef.current.innerHTML = '';
      }
    };
  }, []);

  const handleRegistration = async (event) => {
    event.preventDefault();
    setError('');
    setSuccess('');
    setLoading(true);

    try {
      if (!turnstileToken) {
        throw new Error('Please complete the security check');
      }

      const registrationEndpoint = `${DIRECTUS_INSTANCE}/users`;
      const response = await axios.post(registrationEndpoint, {
        email,
        first_name: firstName,
        last_name: lastName,
        password,
        role: '795c4d4b-4258-4f76-a116-70a03cb46d1a'
      }, {
        headers: {
          'Content-Type': 'application/json'
        }
      });

      if (response.status >= 200 && response.status < 300) {
        // Track registration success event
        if (window.gtag) {
          window.gtag('event', 'registration_complete', {
            'event_category': 'user_acquisition',
            'event_label': 'new_user_registration'
          });
        }
        
        setIsRegistered(true);
        setSuccess('Registration successful!');
        setError('');
        // Clear form fields
        setEmail('');
        setPassword('');
        setFirstName('');
        setLastName('');
      } else {
        setError('Registration failed. Please try again.');
      }
    } catch (error) {
      setError('Registration failed. Please try again.');
    } finally {
      setLoading(false); // Always reset loading state
    }
  };

  // New success screen component
  const SuccessScreen = () => (
    <div className="text-center">
      <div className="mb-8">
        <svg className="mx-auto h-16 w-16 text-primary-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
        </svg>
      </div>
      <h2 className="text-3xl font-bold text-gray-900 mb-4">Welcome aboard!</h2>
      <p className="text-lg text-gray-600 mb-8">Your account has been successfully created.</p>
      <a
        href="/signin"
        className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md text-white bg-primary-500 hover:bg-primary-600"
      >
        Continue to Sign In
      </a>
    </div>
  );

  return (
    <div className="bg-primary-900 grid grid-cols-12 min-h-screen">
      <img
        className="h-8 absolute top-8 left-16 z-50"
        src={reisloLogo}
        alt="Reislo logo"
      />
      <div className="col-span-5 flex items-center justify-center">
        <div className="px-16">
          <h2 className="text-3xl font-medium mb-6 text-white">Elevate Your Content Strategy with AI-Powered Writing Assistant</h2>
          <ul className="space-y-6 text-lg text-white opacity-80">
            <li className="flex gap-3 items-start"><div className="bg-primary-500 rounded-full p-1 inline-block"><RocketLaunchIcon className="w-5 text-primary-900" /></div> <div className="flex flex-col"><p className="font-semibold">Craft Compelling Articles with Data-Driven Insights</p> Generate high-quality articles 70% faster using our vast company knowledge base. </div></li>
            <li className="flex gap-3 items-start"><div className="bg-primary-500 rounded-full p-1"><RocketLaunchIcon className="w-5 text-primary-900" /></div> <div className="flex flex-col"><p className="font-semibold">Create Engaging Product Descriptions Effortlessly</p>Produce persuasive e-commerce content tailored to your brand voice and target audience. </div></li>
            <li className="flex gap-3 items-start"><div className="bg-primary-500 rounded-full p-1"><RocketLaunchIcon className="w-5 text-primary-900" /></div> <div className="flex flex-col"><p className="font-semibold">Optimize Social Media Posts for Maximum Impact</p>Design data-backed social media content that resonates with your followers across platforms. </div></li>
            <li className="flex gap-3 items-start"><div className="bg-primary-500 rounded-full p-1"><RocketLaunchIcon className="w-5 text-primary-900" /></div> <div className="flex flex-col"><p className="font-semibold">Centralize Content Management</p>Manage all your AI-generated content from one intuitive project hub.</div></li>
          </ul>
        </div>
      </div>
      <div className="bg-white py-56 col-span-7 px-64 relative min-h-screen">
        <div className="w-full">
          {isRegistered ? (
            <SuccessScreen />
          ) : (
            <>
              <h2 className="text-4xl text-gray-800 font-medium text-center">
                Register for an account
              </h2>
              <span className="text-center block text-lg text-gray-500">No credit card required
              </span>
              <form className="mt-8 space-y-6" onSubmit={handleRegistration}>
                <div>
                  <label htmlFor="firstName" className="block text-sm font-medium text-gray-700">First Name</label>
                  <input
                    id="firstName"
                    type="text"
                    placeholder="John"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    required
                    className="appearance-none block w-full mt-2 px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
                  />
                </div>
                <div>
                  <label htmlFor="lastName" className="block text-sm font-medium text-gray-700">Last Name</label>
                  <input
                    id="lastName"
                    type="text"
                    placeholder="Smith"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    required
                    className="appearance-none block mt-2 w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
                  />
                </div>
                <div>
                  <label htmlFor="email" className="block text-sm font-medium text-gray-700">Work Email</label>
                  <input
                    id="email"
                    type="email"
                    placeholder="john.smith@companyname.com"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                    className="appearance-none block mt-2 w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
                  />
                </div>
                <div>
                  <label htmlFor="password" className="block text-sm font-medium text-gray-700">Password</label>
                  <input
                    id="password"
                    minLength="8"
                    type="password"
                    placeholder="••••••••"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                    className="appearance-none block w-full px-3 mt-2 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
                  />
                  <PasswordStrengthBar 
                    password={password}
                    minLength={8}
                    scoreWords={['Weak', 'Weak', 'Okay', 'Good', 'Strong']}
                    shortScoreWord="Too Short"
                  />
                </div>
                <div ref={turnstileContainerRef} className="flex justify-center"></div>

                <button
                  type="submit"
                  className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-primary-500 hover:bg-primary-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                  disabled={loading}  // Disable the button while loading
                >
                  {loading ? (
                    <>
                      <svg aria-hidden="true" className="w-6 h-6 mr-4 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                      </svg>
                      Creating account...
                    </>
                  ) : (
                    'Register'
                  )}
                </button>
                {error && <div className="error text-red-500 text-sm mt-3">{error}</div>}
                {success && <div className="text-primary-500 text-sm mt-3">{success}</div>}
              </form>
            </>
          )}
        </div>
        <p className="mt-10 text-center text-sm text-gray-500">
          Do you already have an account?{' '}
          <a href="/signin" className="font-semibold leading-6 text-primary-500 hover:text-primary-500">
            Go to Sign In
          </a>
        </p>
      </div>
    </div>
  );
};

export default Register;
