import axios from 'axios';
import axiosRetry from 'axios-retry';
import React from 'react';
import ReactDOM from 'react-dom';
import Notification from './components/Notifications/Notification';
import { authService } from './services/authService';

// API Endpoints
export const DIRECTUS_INSTANCE = 'https://panel.reislo.com';
export const DIRECTUS_DATASETS_ENDPOINT = `${DIRECTUS_INSTANCE}/items/data_sets`;
export const DIRECTUS_FILES_ENDPOINT = `${DIRECTUS_INSTANCE}/files`;
export const DIRECTUS_PROJECTS_ENDPOINT = `${DIRECTUS_INSTANCE}/items/projects`;
export const OPENAI_COMPLETIONS_INSTANCE = process.env.REACT_APP_OPENAI_COMPLETIONS_INSTANCE;
export const API_URL = process.env.REACT_APP_API_URL;

export const DIRECTUS_PERSONAS_ENDPOINT = `${DIRECTUS_INSTANCE}/items/personas`;
export const DIRECTUS_ARTICLES_ENDPOINT = `${DIRECTUS_INSTANCE}/items/articles`;
export const DIRECTUS_HEATMAPS_ENDPOINT = `${DIRECTUS_INSTANCE}/items/heatmaps`;
export const DIRECTUS_USER_JOURNEYS_ENDPOINT = `${DIRECTUS_INSTANCE}/items/userjourney`;
export const DIRECTUS_ONBOARDING_ENDPOINT = `${DIRECTUS_INSTANCE}/items/onboarding_profiling`;
export const DIRECTUS_CHAT_SESSIONS_ENDPOINT = `${DIRECTUS_INSTANCE}/items/chat_sessions`;

// API Keys
export const REACT_APP_EMBEDINGS_API_KEY = process.env.REACT_APP_EMBEDINGS_API_KEY;
export const REACT_APP_GPT_API_KEY = process.env.REACT_APP_GPT_API_KEY;
export const ASSISTANT_ID = process.env.REACT_APP_ASSISTANT_ID;
export const ANTHROPIC_API_KEY = process.env.REACT_APP_ANTHROPIC_API_KEY;
export const OPENAI_API_KEY = process.env.REACT_APP_OPENAI_API_KEY;
export const PINECONE_API_KEY = process.env.REACT_APP_PINECONE_API_KEY;

// Pinecone Configuration
export const PINECONE_ENVIRONMENT = process.env.REACT_APP_PINECONE_ENVIRONMENT;
export const PINECONE_INDEX_NAME = process.env.REACT_APP_PINECONE_INDEX_NAME;

export const OPENAI_API_URL = 'https://api.openai.com/v1';
export const VECTOR_STORE_ID = process.env.REACT_APP_VECTOR_STORE_ID || 'vs_0aZJbV6SHHz6zKagR2osdkio';

// Create an Axios instance for Directus API
const directusAPI = axios.create({
  baseURL: DIRECTUS_INSTANCE,
  headers: {
    'Content-Type': 'application/json'
  }
});

// Set up axios-retry
axiosRetry(directusAPI, {
  retries: 3,
  retryDelay: axiosRetry.exponentialDelay,
  retryCondition: (error) => error.response?.status === 429 || error.response?.status === 503,
});

// Add an interceptor to include the token in every request
directusAPI.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('directus_token') || sessionStorage.getItem('directus_token');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Create an Axios instance for OpenAI API
const openAIAPI = axios.create({
  baseURL: 'https://api.openai.com/v1',
  headers: {
    'Authorization': `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
    'Content-Type': 'application/json',
    'OpenAI-Beta': 'assistants=v2'
  }
});

// Generic function to handle errors from API requests
const handleApiError = (error) => {
  if (error.response) {
    console.error('Server responded with an error:', error.response.data);
    console.error('Status code:', error.response.status);
  } else if (error.request) {
    console.error('No response received:', error.request);
  } else {
    console.error('Error setting up request:', error.message);
  }
  throw error;
};

// Function to post data to OpenAI API
export const postCompletion = async (postData) => {
  try {
    return await openAIAPI.post('', postData);
  } catch (error) {
    return handleApiError(error);
  }
};

export const queryPinecone = async (vector, topK = 5) => {
  try {
    const response = await axios.post(`${API_URL}/query`, { vector, topK });
    return response.data;
  } catch (error) {
    return handleApiError(error);
  }
};


export const saveProjectData = async (dataToSave) => {
  try {
    const response = await directusAPI.post('/items/projects', dataToSave);
    console.log('Project data saved successfully:', response.data);
    return response.data;
  } catch (error) {
    return handleApiError(error);
  }
};

export const createThread = async () => {
  try {
    const response = await openAIAPI.post('/threads');
    return response.data.id;
  } catch (error) {
    console.error('Error creating thread:', error);
    throw error;
  }
};

export const addMessageToThread = async (threadId, message) => {
  // Ensure the message content is properly formatted according to OpenAI's API requirements
  const formattedMessage = {
    role: message.role,
    content: message.content
  };

  const createNewThreadAndSendMessage = async () => {
    try {
      const newThreadId = await createThread();
      console.log('Created new thread:', newThreadId);
      
      const retryResponse = await openAIAPI.post(`/threads/${newThreadId}/messages`, {
        role: formattedMessage.role,
        content: formattedMessage.content
      });

      return {
        ...retryResponse.data,
        new_thread_id: newThreadId
      };
    } catch (retryError) {
      console.error('Error sending message to new thread:', retryError);
      console.error('Error response:', retryError.response?.data);
      throw new Error(`Failed to send message to newly created thread: ${retryError.response?.data?.error?.message || retryError.message}`);
    }
  };

  try {
    console.log('Sending formatted message:', formattedMessage);
    const response = await openAIAPI.post(`/threads/${threadId}/messages`, {
      role: formattedMessage.role,
      content: formattedMessage.content
    });
    return response.data;
  } catch (error) {
    console.error('Error details:', {
      message: error.message,
      response: error.response?.data,
      status: error.response?.status
    });
    
    if (error.response?.status === 400 || error.response?.status === 404) {
      console.log(`Thread ${error.response.status === 400 ? 'invalid/expired' : 'not found'}, creating new thread...`);
      return await createNewThreadAndSendMessage();
    }
    
    throw error;
  }
};

export const runAssistant = async (threadId, assistantId, options = {}) => {
  try {
    const runRequest = {
      assistant_id: assistantId,
      instructions: options.instructions || undefined,
      tools: [{ "type": "file_search" }]
    };

    // Convert vector_store_ids to a string if present
    if (options.file_search?.vector_store_ids?.length > 0) {
      runRequest.metadata = {
        file_search: JSON.stringify({
          vector_store_ids: options.file_search.vector_store_ids
        })
      };
    }

    console.log('Run request payload:', JSON.stringify(runRequest, null, 2));
    
    const response = await openAIAPI.post(`/threads/${threadId}/runs`, runRequest);
    console.log('Run response:', response.data);
    
    return response.data.id;
  } catch (error) {
    console.error('Run assistant error details:', {
      message: error.message,
      response: error.response?.data,
      status: error.response?.status,
      request: error.config?.data
    });
    
    if (error.response?.data?.error) {
      throw new Error(`OpenAI API Error: ${error.response.data.error.message}`);
    }
    throw error;
  }
};

export const checkRunStatus = async (threadId, runId) => {
  const response = await openAIAPI.get(`/threads/${threadId}/runs/${runId}`);
  return response.data;
};

export const listMessages = async (threadId) => {
  const response = await openAIAPI.get(`/threads/${threadId}/messages`);
  return response.data;
};

let notificationContainer = null;

const displayNotification = (assistantId, vectorStoreId) => {
  if (!notificationContainer) {
    notificationContainer = document.createElement('div');
    document.body.appendChild(notificationContainer);
  }

  ReactDOM.render(
    <Notification assistantId={assistantId} vectorStoreId={vectorStoreId} />,
    notificationContainer
  );
};

export const getAssistantId = async () => {
  try {
    const token = localStorage.getItem('directus_token') || sessionStorage.getItem('directus_token');
    const userId = localStorage.getItem('user_id');

    if (!token || !userId) {
      return ASSISTANT_ID;
    }

    const userResponse = await directusAPI.get(`/users/${userId}`, {
      params: { fields: ['linked_workspace'] }
    });

    const workspaceId = userResponse.data.data.linked_workspace;

    if (workspaceId) {
      const workspaceResponse = await directusAPI.get(`/items/workspace/${workspaceId}`, {
        params: { fields: ['assistant_id'] }
      });

      const workspaceAssistantId = workspaceResponse.data.data.assistant_id;

      if (workspaceAssistantId) {
        return workspaceAssistantId;
      }
    }

    return ASSISTANT_ID;
  } catch (error) {
    console.error('Error fetching assistant ID:', error);
    return ASSISTANT_ID;
  }
};

export const getVectorStoreId = async () => {
  try {
    const token = localStorage.getItem('directus_token') || sessionStorage.getItem('directus_token');
    const userId = localStorage.getItem('user_id');

    if (!token || !userId) {
      return VECTOR_STORE_ID;
    }

    const userResponse = await directusAPI.get(`/users/${userId}`, {
      params: { fields: ['linked_workspace'] }
    });

    const workspaceId = userResponse.data.data.linked_workspace;

    if (workspaceId) {
      const workspaceResponse = await directusAPI.get(`/items/workspace/${workspaceId}`, {
        params: { fields: ['vector_store_id'] }
      });

      const workspaceVectorStoreId = workspaceResponse.data.data.vector_store_id;

      if (workspaceVectorStoreId) {
        return workspaceVectorStoreId;
      }
    }

    return VECTOR_STORE_ID;
  } catch (error) {
    console.error('Error fetching vector store ID:', error);
    return VECTOR_STORE_ID;
  }
};

// Function to initialize and display the notification
export const initializeNotification = async () => {
  const assistantId = await getAssistantId();
  const vectorStoreId = await getVectorStoreId();
  displayNotification(assistantId, vectorStoreId);
};

// Call initializeNotification when the file is loaded
initializeNotification();

// Handle API errors globally
directusAPI.interceptors.response.use(
  response => response,
  async error => {
    const originalRequest = error.config;
    
    if (error.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      
      const refreshSuccessful = await authService.refreshToken();
      if (refreshSuccessful) {
        originalRequest.headers['Authorization'] = `Bearer ${authService.getToken()}`;
        return directusAPI(originalRequest);
      }
      
      // If refresh failed, logout
      window.dispatchEvent(new CustomEvent('auth:logout'));
      return Promise.reject(error);
    }
    
    return Promise.reject(error);
  }
);

export { directusAPI };

export const ITEMS_PER_PAGE = 50;

export const fetchArticles = async (token, userId, currentPage) => {
  try {
    const response = await axios.get(`${DIRECTUS_ARTICLES_ENDPOINT}`, {
      headers: { Authorization: `Bearer ${token}` },
      params: {
        filter: { user_created: { id: { _eq: userId } } },
        limit: ITEMS_PER_PAGE,
        page: currentPage,
        sort: '-date_created',
        meta: 'total_count,filter_count',
        fields: '*,user_created.first_name,type'
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching articles:', error);
    throw error;
  }
};

export const formatDate = (dateString) => {
  const options = { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit', hour24: false };
  return new Intl.DateTimeFormat('en-US', options).format(new Date(dateString));
};

export const getChatSessions = async () => {
  try {
    const response = await directusAPI.get(DIRECTUS_CHAT_SESSIONS_ENDPOINT, {
      params: {
        sort: '-date_created',
      },
    });
    return response.data.data;
  } catch (error) {
    console.error('Error fetching chat sessions:', error);
    throw error;
  }
};

export const createChatSession = async (title, threadId, assistantId, vectorStoreId) => {
  try {
    const response = await directusAPI.post(DIRECTUS_CHAT_SESSIONS_ENDPOINT, {
      title,
      thread_id: threadId,
      assistant_id: assistantId,
      vector_store_id: vectorStoreId,
    });
    return response.data.data;
  } catch (error) {
    console.error('Error creating chat session:', error);
    if (error.response) {

    }
    throw error;
  }
};

export const updateChatSession = async (sessionId, data) => {
  try {
    const response = await directusAPI.patch(`${DIRECTUS_CHAT_SESSIONS_ENDPOINT}/${sessionId}`, data);
    return response.data.data;
  } catch (error) {
    console.error('Error updating chat session:', error);
    throw error;
  }
};

export const saveChatMessage = async (sessionId, messageData, threadId) => {
  try {
    const response = await directusAPI.post('/items/chat_messages', {
      session_id: sessionId,
      thread_id: threadId,
      role: messageData.role,
      content: messageData.content,
      timestamp: new Date().toISOString(),
      order: messageData.order
    });
    return response.data.data;
  } catch (error) {
    console.error('Error saving chat message:', error);
    throw error;
  }
};

export const fetchChatSessions = async (token, userId, page, sessionId = null) => {
  try {
    const params = {
      filter: { user_created: { _eq: userId } },
      sort: '-date_created',
      page: page,
      limit: ITEMS_PER_PAGE,
    };

    if (sessionId) {
      params.filter.id = { _eq: sessionId };
      params.fields = ['*', 'messages.*'];
    }

    const response = await directusAPI.get('/items/chat_sessions', { params });
    return response.data;
  } catch (error) {
    console.error('Error fetching chat sessions:', error);
    throw error;
  }
};

export const getChatMessages = async (sessionId) => {
  try {
    const response = await directusAPI.get('/items/chat_messages', {
      params: {
        filter: { session_id: { _eq: sessionId } },
        sort: 'order'
      }
    });
    return response.data.data;
  } catch (error) {
    console.error('Error fetching chat messages:', error);
    throw error;
  }
};

// OpenAI Files API endpoints
export const listOpenAIFiles = async () => {
  try {
    const userId = localStorage.getItem('user_id');
    const vectorStoreId = await getVectorStoreId();
    
    // Get all files
    const filesResponse = await openAIAPI.get('/files');
    
    // Get files for this specific vector store
    const vectorStoreFilesResponse = await openAIAPI.get(`/vector_stores/${vectorStoreId}/files`, {
      headers: {
        'OpenAI-Beta': 'assistants=v2'
      }
    });
    
    // Get the IDs of files in the vector store
    const vectorStoreFileIds = vectorStoreFilesResponse.data.data.map(file => file.id);
    
    // Filter files to only include those in the vector store
    const filteredFiles = filesResponse.data.data.filter(file => 
      vectorStoreFileIds.includes(file.id)
    );

    return { data: filteredFiles };
  } catch (error) {
    console.error('OpenAI API Error:', error);
    throw error;
  }
};

export const getOpenAIFile = async (fileId) => {
  try {
    const response = await openAIAPI.get(`/files/${fileId}`);
    return response.data;
  } catch (error) {
    console.error('Error getting OpenAI file:', error);
    throw error;
  }
};

export const deleteOpenAIFile = async (fileId) => {
  try {
    const response = await openAIAPI.delete(`/files/${fileId}`);
    return response.data;
  } catch (error) {
    console.error('Error deleting OpenAI file:', error);
    throw error;
  }
};

export const deleteVectorStoreFile = async (fileId) => {
  try {
    const vectorStoreId = await getVectorStoreId();
    
    try {
      // First try to remove from vector store
      await openAIAPI.delete(`/vector_stores/${vectorStoreId}/files/${fileId}`, {
        headers: {
          'OpenAI-Beta': 'assistants=v2'
        }
      });
    } catch (vectorStoreError) {
      // If file is not in vector store, continue with deletion
      console.log('File not found in vector store or already removed');
    }
    
    // Then delete the OpenAI file itself
    await openAIAPI.delete(`/files/${fileId}`);
    
    return { success: true };
  } catch (error) {
    console.error('Error deleting file:', error);
    throw error;
  }
};