import { createThread, addMessageToThread, runAssistant, checkRunStatus, listMessages } from '../../api';
import { directusAPI } from '../../api';

export const getOrCreateThread = async (existingThreadId) => {
  if (existingThreadId) {
    return existingThreadId;
  }
  const newThread = await createThread();
  return newThread.id;
};

export const setupAssistantPrompt = async (threadId, context) => {
  if (!threadId) {
    throw new Error('Thread ID is undefined');
  }

  try {
    // Fetch workspace data if not provided in context
    let workspacePrompt = context?.workspace?.assistant_prompt;
    
    if (!workspacePrompt) {
      const userId = localStorage.getItem('user_id');
      const userResponse = await directusAPI.get(`/users/${userId}`, {
        params: { fields: ['linked_workspace'] }
      });
      
      const workspaceId = userResponse.data.data.linked_workspace;
      
      if (workspaceId) {
        const workspaceResponse = await directusAPI.get(`/items/workspace/${workspaceId}`, {
          params: { fields: ['assistant_prompt'] }
        });
        workspacePrompt = workspaceResponse.data.data.assistant_prompt;
      }
    }

    const setupMessages = [
      {
        role: 'user',
        content: `Search the vector store for information relevant to the user's question and follow the rules below`
      },
      {
        role: 'user',
        content: `Your goal is to help users with their questions in a natural, conversational way.

CRITICAL SOURCE CITATION RULES:
1. You MUST cite a source for EVERY SINGLE statement you make - NO EXCEPTIONS
2. If you cannot cite a source, you MUST NOT make that statement
3. Format ALL sources exactly as: "[Source: filename.ext]"
4. Generic medical or fitness advice without specific sources is NOT allowed
5. Each paragraph MUST end with at least one source citation
6. Responses without sources will be considered incorrect and must be rejected

Response guidelines:
1. Write in a personal, direct tone - use "we recommend" instead of "documents suggest"
2. Structure your responses in clear paragraphs
3. Be friendly and helpful while maintaining strict source citation`
      },
      {
        role: 'user',
        content: `Source citation examples:

CORRECT:
"Our application offers a workout tracking feature. [Source: features.md]
The recommended rest period between workouts is 48 hours. [Source: training-guidelines.pdf]"

INCORRECT:
"You should rest between workouts. (NO SOURCE - NOT ALLOWED)
Our app has many features. (NO SOURCE - NOT ALLOWED)
Exercise is important. (NO SOURCE - NOT ALLOWED)"

If you cannot find a source for information:
1. DO NOT make general statements based on common knowledge
2. DO NOT provide medical or fitness advice without sources
3. Instead, say: "I can only provide information from our documented sources. For this specific topic, I recommend consulting with a qualified professional."
4. Then cite relevant documented information you DO have sources for`
      },
      {
        role: 'user',
        content: `When handling information:
1. ALWAYS include at least one source for each claim or statement you make
2. If you don't have exact information:
   - Share relevant related information you do have (with sources)
   - Provide practical advice based on available files (with sources)
   - End with an invitation to contact support for personalized guidance
3. Never mention searching or not finding information
4. If you're making a general statement based on multiple sources, cite all relevant sources`
      },
      {
        role: 'user',
        content: `Formatting rules:
1. Use markdown for formatting
2. Use code blocks with appropriate language tags for technical content
3. Answer in the same language as the question
4. Maintain a friendly, helpful tone throughout
5. Break up long responses into digestible paragraphs
6. If someone asks about something that is not related to the application, please politely ask them to focus their questions on the application`
      },
      {
        role: 'user',
        content: workspacePrompt || 'Please focus on helping users with application-related questions.'
      }
    ];

    console.log(`Initializing thread with prompts: ${threadId}`);
    console.log('Setting up assistant prompts:', setupMessages);

    for (const message of setupMessages) {
      const response = await addMessageToThread(threadId, message);
      console.log('Prompt setup response:', response);
    }
  } catch (error) {
    console.error('Error in setupAssistantPrompt:', error);
    throw error;
  }
};

export const sendMessageAndGetResponse = async (threadId, assistantId, vectorStoreId, userMessage) => {
  try {
    if (!threadId) {
      throw new Error('Thread ID is undefined');
    }

    if (!vectorStoreId) {
      console.warn('Vector store ID is undefined or empty');
    }

    console.log('Vector search parameters:', {
      threadId,
      assistantId,
      vectorStoreId,
      messageLength: userMessage.length
    });

    console.log(`Adding message to thread: ${threadId}`);
    await addMessageToThread(threadId, {
      role: 'user',
      content: userMessage,
    });

    const runParameters = {
      file_search: { 
        vector_store_ids: [vectorStoreId],
        debug: true,
        return_search_results: true
      }
    };
    console.log('Running assistant with detailed parameters:', {
      threadId,
      assistantId,
      vectorStoreId,
      runParameters,
      messageContent: userMessage
    });
    
    const runId = await runAssistant(threadId, assistantId, runParameters);

    let runStatus;
    let attempts = 0;
    const maxAttempts = 30;
    
    do {
      const runData = await checkRunStatus(threadId, runId);
      runStatus = runData.status;
      
      console.log(`Run status details (attempt ${attempts + 1}/${maxAttempts}):`, {
        status: runStatus,
        runId,
        steps: runData.steps?.map(step => ({
          status: step.status,
          type: step.type,
          step_details: step.step_details,
          last_error: step.last_error
        })),
        tool_outputs: runData.required_action?.submit_tool_outputs?.tool_calls,
        search_results: runData.search_results,
        metadata: runData.metadata
      });
      
      if (runStatus === 'completed') break;
      if (runStatus === 'failed') {
        console.error('Run failed with data:', runData);
        throw new Error(`Assistant run failed: ${JSON.stringify(runData)}`);
      }
      
      await new Promise(resolve => setTimeout(resolve, 2000));
      attempts++;
      
      if (attempts >= maxAttempts) {
        throw new Error('Assistant run timed out after 60 seconds');
      }
    } while (runStatus === 'in_progress');

    console.log('Fetching messages');
    const messagesRes = await listMessages(threadId);
    const assistantMessage = messagesRes.data.find(msg => msg.role === 'assistant');

    if (!assistantMessage) {
      console.error('No assistant message found');
      return null;
    }

    console.log('Final run results:', {
      threadId,
      runId,
      messageCount: messagesRes?.data?.length,
      hasAssistantMessage: !!assistantMessage,
      responseLength: assistantMessage?.content[0]?.text?.value?.length
    });

    return assistantMessage.content[0].text.value;
  } catch (error) {
    console.error('Error in sendMessageAndGetResponse:', error);
    throw error;
  }
};